.popOverOwnerInfo{
  width: 320px;
  z-index: 999 !important;

  .UserChangeDetail h2{
    margin: 0;
    font-size: 1.25em;
    text-align: center;
  }

  .UserChangeDetail h2 span{
    font-size: 0.75em;
    margin-bottom: 0;
    text-align: center;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
    font-weight: 100;
  }

  .UserChangeDetail p{
    margin-bottom: 0;
    text-align: center;
  }

}



