@import "src/variables";

.SearchList {
  span.bp3-breadcrumb {
    color: rgba(70, 70, 70, 1);
    font-family: $gilroy-extra-bold;
    font-size: 1em;
    box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
    -webkit-box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
    -moz-box-shadow: 0 0 0 1px rgba(70, 70, 70, 1) inset;
    padding: 0.25em 0.5em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .RankBlock {
    text-align: center;
    margin-top: -15px;
  }

  .newUserText {
    text-align: center;
    margin-top: -15px;
  }

  .IconGold {
    color: #ffbb05;
  }
}
