@import "src/variables";

.ActivitySuccessfulChange {
  // HEADER ACTIVITY
  .headerUserCard {
    position: relative;
    display: flex;

    div {
      flex-flow: row wrap;
      justify-content: space-around;
    }

    div.AvatarContainer {
      position: relative;
      width: 48px;
      height: 48px;
      background: #ebebeb;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      overflow: hidden;
      min-width: 48px;
      min-height: 48px;
    }

    .AvatarContainer .AvatarInitials {
      position: absolute;
      width: 100%;
      height: 100%;
      font-family: $gilroy-extra-bold;
      display: flex;
      align-items: center;
      align-content: center;
      color: rgba(70, 70, 70, 1) !important;
    }

    .AvatarContainer .AvatarInitials p {
      text-align: center;
      margin-bottom: 0;
    }

    .AvatarContainer .AvatarImage {
      position: absolute;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .AvatarContainer .AvatarImage img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .UserDetailsContainer {
      padding-left: 1em;
      width: 100%;
      padding: 0 0.5em;
      display: block;
    }

    .UserDetailsContainer h4 {
      font-family: $gilroy-extra-bold;
      font-size: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }

    .UserDetailsContainer h4 a {
      color: rgba(70, 70, 70, 1) !important;
    }

    .UserDetailsContainer p {
      font-size: 1em;
      margin-bottom: 0;
    }

    .UserDetailsContainer p span {
      font-family: $gilroy-extra-bold;
      margin: 0 0.25em 0 0;
    }

    .UserDetailsContainer .bp3-icon {
      margin: 0 0.25em 0 0.25em;
    }

    .UserDetailsContainer p .bp3-icon svg {
      width: 12px;
      height: 12px;
    }

    .UserDetailsContainer .optionsButton {
      position: absolute;
      top: 20px;
      right: 0em;
    }

    .UserDetailsContainer .optionsButton .bp3-icon {
      margin: 0;
    }
  }

  h1 {
    font-size: 1.125em;
  }

  // POPOVER USER INFORMATION CONTAINER
  .userActivePublicationsList {
    width: 100%;
    height: 100px;
  }

  .userActivePublicationsList .userActiveAvatar {
    width: 20%;
    height: 0;
    padding-bottom: 20%;
  }

  // TYPE ACTIVITY MIDDLE CONTENT
  .NewPublicationTypeContainer {
    position: relative;
    // display: flex;
    // flex-wrap: wrap;

    // div {
    //   // flex-flow: row wrap;
    //   // justify-content: space-around;
    //   // flex-grow: 1;
    //   width: 100%;
    //   min-width: 50%;
    // }

    // div:first {
    //   width: 45%;
    // }

    // div:nth-child(2) {
    //   width: 100%;
    // }

    .productImageContainer {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      // background: #ffffff;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      overflow: hidden;
      position: relative;
    }

    .productImageContainer figure {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .productImageContainer figure .productImageControls {
      position: absolute;
      z-index: 100;
      top: 0.25em;
      left: 0.25em;
      z-index: 10;
    }

    .productImageContainer figure .productImageControls div {
      margin-bottom: 0.25em;
      margin-left: 0;
      transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      transform: translateX(-40px);
      -webkit-transform: translateX(-40px);
      -moz-transform: translateX(-40px);
    }

    .productImageContainer figure .productImageControls div p {
      margin-right: 0.25em;
      margin-bottom: 0;
    }

    .productImageContainer figure:hover .productImageControls div {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
    }

    .productImageContainer figure .productImageControls div:nth-child(0) {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
    }

    .productImageContainer figure .productImageControls div:nth-child(1) {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
    }

    .productImageContainer figure:hover .productImageControls div:nth-child(2) {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
    }

    .publicationDetailsContainer h1 {
      font-size: 1.125em;
      margin-top: 0;
    }

    .publicationDetailsContainer h1 a {
      color: rgba(0, 123, 255, 1) !important;
    }

    .publicationDetailsContainer p {
      margin-bottom: 0;
    }
  }

  .changeDesignLabel {
    padding: 0.5em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    display: inline-flex;
    position: relative;
  }

  .changeDesignLabel h2 {
    font-size: 1em;
    vertical-align: middle;
    color: #fff !important;
  }

  .changeDesignLabel span.bp3-icon svg {
    font-size: 2em;
    fill: #fff;
  }

  div.swiper-button-next {
    width: 25px;
    height: 25px;
    right: 0.5em;
    min-width: 25px;
  }

  div.swiper-button-prev {
    width: 25px;
    height: 25px;
    left: 0.5em;
    min-width: 25px;
  }

  div.swiper-pagination-bullets {
    bottom: 1em;
  }

  @media screen and (max-width: 767px) {
    .NewPublicationTypeContainer {
      display: block;
    }

    .publicationDetailsContainer {
      margin-left: 0;
    }
  }
}
