@import 'src/variables';

.headerNavBar {
  // .LCColorBackground{
  //     background: rgba(255, 187, 5, 1)  !important;
  // }
  padding-bottom: 5.5rem;
  margin-bottom: 44px !important;
  height: auto;
  width: 100%;

  .loCambioNavbar {
    box-shadow: none !important;
    z-index: 20;
  }

  .personal-icon {
    margin-right: 1rem;
  }

  .black {
    color: black;
  }

  button {
    color: #fff;
    font-family: 'gilroy-extra-bold', Helvetica, Arial, serif;
  }

  .bp3-button-text {
    font-size: 18px;
  }

  .first-section-nav-buttons {
    font-family: 'gilroy-bold', Helvetica, Arial, serif;
    font-size: 20px;
    padding-left: 0.5rem;
    color: #2d2d2d;
  }

  .bp3-icon {
    color: #2d2d2d;
  }

  .nav-content {
    height: fit-content;
  }

  .logged-section {
    width: 20rem;
    justify-content: space-evenly;
  }

  .third-section-navbar {
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
  }

  .third-section-buttons {
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .options-buttons {
    border-radius: 5px;
    font-family: 'gilroy-extra-bold', Helvetica, Arial, serif;
    padding: 0.8rem 1rem;
    margin-top: 0.7rem;
  }

  .mural-button {
    color: black;
    background-color: white;
  }

  .publication-button {
    background-color: #8051da;
    color: white;
    margin-right: 1rem;
    width: auto;
    align-items: center;
  }

  .logo-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    padding-left: 3rem;
  }

  .align-center {
    align-items: center;
    width: 100%;
  }

  .vertical-line {
    border-left: 2px #2d2d2d solid;
    height: 20px;
    margin: 0 1rem;
  }

  .vertical-line-white {
    border-left: 2px white solid;
    height: 25px;
    margin: 0rem 1rem;
  }

  .white-text {
    color: white;
  }

  .visitor-options {
    margin: 0 1rem;
    font-family: 'gilroy-bold', Helvetica, Arial, serif;
    font-size: 15px;
  }

  .bp3-navbar-heading {
    margin-right: 10px;
  }

  .App-logo {
    width: 225px;
  }

  .searchbarBtn {
    span {
      background: transparent !important;
      color: rgba(70, 70, 70, 1) !important;
    }
  }

  .searchbarBtn:hover {
    span {
      background: transparent !important;
    }
  }

  .margin-not-loged {
    margin-left: 1rem;
  }

  .bubbleAlert {
    background: #c23030;
    padding: 0.175em 0.5em;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    margin-top: -10px;
    margin-left: -0.5em;
    font-size: 0.85em;
    position: relative;
    top: 0%;
    left: 0%;
  }

  .headerUserCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0.25rem 0.1rem;
  }

  .headerUserCard div.AvatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: unset;
    height: unset;
    padding-bottom: 6%;
    min-width: 30px;
    min-height: 30px;
    position: relative;
  }

  .AvatarContainer .AvatarInitials p span {
    color: rgba(70, 70, 70, 1) !important;
  }

  .bp3-navbar.bp3-fixed-top {
    height: auto;
  }

  .Avatarname {
    float: none;
    float: right;
  }

  .user-details-container {
    padding: 3rem;
  }

  .ClearFix {
    overflow: auto;
  }

  .remove-rounded-corners {
    border-radius: 0;
  }

  /******************** MEDIA QUERY ********************/

  @media screen and (max-width: 1024px) {
    padding-bottom: 6.8rem;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 8.9rem;

    .App-logo {
      width: 10rem;
      max-width: 8rem;
      margin-left: 0px;
    }

    .logo-buttons {
      padding: 0.5rem;
    }

    .mobile-disappear {
      display: none;
    }

    .visitor-options {
      margin: 0;
    }

    .mobile-column {
      flex-direction: column;
    }

    .logged-section {
      width: 8rem;
      justify-content: space-around;
    }

    .options-buttons {
      margin-right: 0;
      margin-left: 1rem;
    }

    .publication-button {
      margin-top: 2.25rem;
    }
  }

  @media screen and (max-width: 425px) {
    padding-bottom: 7rem;
  }

  @media screen and (max-width: 375px) {
    padding-bottom: 7.5rem;
  }
}

.pop-perfil {
  width: 100%;

  .white-text {
    color: white;
    font-size: 20px;
  }

  .bp3-form-helper-text {
    color: white;
    font-size: 18px;
    font-family: 'gilroy-bold', Helvetica, Arial, serif;
  }

  .user-details-container {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    margin-left: 1rem;
  }

  .padding-popover-user {
    padding: 1.5rem 1rem;
  }

  .user-menu {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    grid-gap: 0.3rem;
  }

  .fund-row {
    align-items: center;
    justify-content: space-between;
    gap: 0.2rem;
    grid-gap: 0.2rem;
  }

  .funds-button {
    background-color: #8051da;
    border-radius: 50%;
    padding: 0.1rem;
  }

  .user-menu-option {
    color: #2d2d2d !important;
    padding: 0.5rem;
  }

  .remove-margin-top {
    margin-top: 0;
    color: #2d2d2d;
    padding: 0 1rem;
  }

  .menu-button {
    width: 100%;
    justify-content: flex-start;
  }

  .user-menu-publish {
    color: #8051da !important;
  }
}

.scroll-80 {
  overflow: auto;
  max-height: 80vh;
}

.AvatarPerfil {
  float: right;
}

.menu-custom {
  align-items: center;

  .personal-icon {
    padding: 0;
    margin: 0 1rem 0 1.5rem;
  }
}
