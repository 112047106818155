.adWall {
  max-width: 600px;

  .adImage {
    width: 100%;
  }

  h5 {
    color: rgba(0, 123, 255, 1) !important;
  }
}

.mrec {
  width: 95%;
  margin-left: -10px;
}

.mrec .adImage {
  width: 100%;
}

.rounded-t {
  width: 100%;
}
