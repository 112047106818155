@import "../../variables";

.MyPublications {
}

.buttonContainer {
  padding: 1rem 0;
  margin-left: -0.5rem;

  .icon {
    padding: 0;
    padding-right: 1rem;
    color: #fff;
  }

  .purpleButton {
    margin: 0 0.5rem;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    padding: 0.75rem 1rem;
    background: $accent !important;
    font: normal normal 800 14px/17px Gilroy !important;
    color: #ffffff !important;
  }

  .orangeButton {
    margin: 0 0.5rem;
    padding: 0.75rem 1rem;
    background: $primary !important;
    font: normal normal 800 14px/17px Gilroy !important;
    color: #ffffff !important;
  }

  .blackButton {
    margin: 0 0.5rem;
    padding: 0.75rem 1rem;
    background: #2d2d2d !important;
    font: normal normal 800 14px/17px Gilroy !important;
    color: #f8b723 !important;
  }
}
