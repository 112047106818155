.product-modal-img {
  object-fit: contain;
  max-height: 90vh;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}
