.LoCambioSeguro {
  .CallToActionAPP {
    @media screen and (max-width: 600px) {
      .minCustomWrapper {
        max-width: 100%;
      }

      .FeaturedCategoriesContainer {
        padding: 1em;
      }

      .CallToActionAPP .appMockUp {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 100px;
      }
    }
  }

  .LoCambioSeguroLogo {
    width: 40%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
  }
}
