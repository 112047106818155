section.NewPublicaition {
  .gradientBackground {
    width: 100%;
    height: 40vh;
    position: absolute;
    // top: 0;
    left: 0;
    right: 0;
  }
}

.FooterContainer {
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0%;
  right: auto;
  left: auto;
}



@media (max-width: 1096px) {
  .sm\:pt-20 {
    padding-top: 7rem;
  }
}
