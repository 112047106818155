@import "src/variables";

.verticalThumbnail {
  width: 100%;
  height: 18.43rem;
  background: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  position: relative;
  //  max-width: 240px;
  //  min-width: 240px;
  //  min-height: 540px;

  figure {
    transform: scale3d(1, 1, 1) !important;
    -webkit-transform: scale3d(1, 1, 1) !important;
    -moz-transform: scale3d(1, 1, 1) !important;
  }

  figure:hover {
    transform: scale3d(2, 2, 2);
    -webkit-transform: scale3d(2, 2, 2);
    -moz-transform: scale3d(2, 2, 2);
    transition: all ease-in 2s;
    -webkit-transition: all ease-in 2s;
    -moz-transition: all ease-in 2s;
  }

  figure h4 {
    font-size: 1.25em;
    margin: 0.5em 0;
  }

  figure .productImage {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  figure .productImage img {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
  }

  .productImageControls {
    position: absolute;
    z-index: 100;
    top: 0.25em;
    left: 0.25em;
    z-index: 10;
  }

  figure .productImageControls div {
    margin-bottom: 0.25em;
    margin-left: 0;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    transform: translateX(-40px);
    -webkit-transform: translateX(-40px);
    -moz-transform: translateX(-40px);
  }

  figure .productImageControls div p {
    margin-right: 0.25em;
    margin-bottom: 0;
  }

  figure:hover .productImageControls div {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
  }

  figure .productImageControls div:nth-child(0) {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
  }

  figure .productImageControls div:nth-child(1) {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
  }

  figure:hover .productImageControls div:nth-child(2) {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
  }

  .UserInfoBox {
    width: 100%;
    position: absolute;
    bottom: 0%;
    margin: 0px auto;
    margin-bottom: -4px;
    margin-left: -20%;
    display: contents;
    //padding-top: 5px;
    //margin-right: 0px;
    //margin-bottom: -10px;
  }

  .headerUserCard {
    position: relative;
    display: contents;
    margin-bottom: 0;

    div {
      flex-flow: row wrap;
      justify-content: space-around;
    }

    div.AvatarContainer {
      position: relative;
      width: 42px;
      height: 42px;
      background: #ebebeb;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      overflow: hidden;
      min-width: 42px;
      min-height: 42px;
      max-width: 42px;
      max-height: 42px;
    }

    .AvatarContainer .AvatarInitials {
      position: absolute;
      width: 100%;
      height: 100%;
      font-family: $gilroy-extra-bold;
      display: flex;
      align-items: center;
      align-content: center;
    }

    .AvatarContainer .AvatarInitials p {
      text-align: center;
      margin-bottom: 0;
    }

    .AvatarContainer .AvatarImage {
      position: absolute;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .AvatarContainer .AvatarImage img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .thumbnailFooter {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
    }
  }

  .fixHeight--50px {
    min-height: 50px;
  }

  // .fixHeight--80px {
  //   min-height: 80px;
  // }

  .fixHeight--100px {
    min-height: 100px;
  }

  .Cond {
    padding-top: 10px;
  }

  /********** MEDIA QUERY **********/

  @media screen and (min-width: 410px) {
    .productImageControls {
      margin-left: -15px;
    }

    figure .productImage {
      height: 200px;
    }
  }

  @media screen and (min-width: 767px) {
    height: -16.563rem;
  }

  // @media screen and (min-width: 768px) {
  //   .productImageControls {
  //     margin-left: 0px;
  //   }
  // }
}

.VerticalThumbPublicationName {
  color: #444444 !important;
}

.verticalThumbnail .headerUserCard div.AvatarContainer {
  background: #d9d9d9;
}
