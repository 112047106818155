.Footer{

  ul{
    list-style: none !important;
  }

  ul li {
    float: left;
    margin-bottom: 0.5em;
  }

}