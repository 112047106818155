@import "src/variables";

.UserInformationContainer{

  .WallActivityUserInformation{

    .backgroundImageAvatar{
      background: rgba(70, 70, 70, 0.125);
      width: 100%;
      height: auto;
    }

    .backgroundImageAvatar figure{
      position: relative;
      overflow: hidden;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px px 0 0;
      -moz-border-radius: 3px 3px 0 0;
    }

    .backgroundImageAvatar figure .backgroundImage{
      z-index: 1;
      transform: scale3d(1.5, 1.5, 1.5);
      -webkit-transform: scale3d(1.5, 1.5, 1.5);
      -moz-transform: scale3d(1.5, 1.5, 1.5);
      background: #ececec;
    }

    .backgroundImageAvatar figure .backgroundImage img{
      filter: blur(0px);
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
    }

    .backgroundImageAvatar figure:hover .backgroundImage img{
      filter: blur(5px);
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
    }

    .backgroundImageAvatar figure .overlayBackgroundImageAvatar{
      width: 100%;
      height: 100%;
      background: rgba(70, 70, 70, 1);
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-content: center;
      align-items: center;
      opacity: 0;
      padding: 1em;
      transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
    }

    .backgroundImageAvatar figure:hover .overlayBackgroundImageAvatar{
      opacity: 1;
      background: rgba(70, 70, 70, 0.75);
    }

    .backgroundImageAvatar figure .overlayBackgroundImageAvatar p{
      color: rgba(255, 255, 255, 0);
      text-align: center;
      font-size: 0.8em;
    }

    .backgroundImageAvatar figure:hover .overlayBackgroundImageAvatar p{
      color: rgba(255, 255, 255, 1);
    }

    .backgroundImageAvatar figure .editButton{
      position: absolute;
      z-index: 3;
      top: 0.5em;
      left: 0.5em;
      transform: translateX(-50px);
      -webkit-transform: translateX(-50px);
      -moz-transform: translateX(-50px);
      transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
    }

    .backgroundImageAvatar figure:hover .editButton{
      position: absolute;
      z-index: 3;
      top: 0.5em;
      left: 0.5em;
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
    }

    .User-Avatar-Name{
      width: 100%;
    }

    .avatarContainer{
      width: 100px;
      height: 100px;
      transform: translateY(-0.5rem);
      padding: 0;
      background-color: #E8E8E8;
    }

    .initialsName {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .userAvatar{
      margin-right: 0.5em;
      width: 100px;
      height: 100px;
      background: #ffffff;
      color: #464646;
      display: flex;
      align-items: center;
      align-content: center;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      position: relative;
      margin-top: -50px;
      left: calc(50% - 50px);
      box-shadow: 0 0 0 2px #fff, 0 4px 4px rgba(70, 70, 70, 0.5);
      -webkit-box-shadow: 0 0 0 2px #fff, 0 4px 4px rgba(70, 70, 70, 0.5);
      -moz-box-shadow: 0 0 0 2px #fff, 0 4px 4px rgba(70, 70, 70, 0.5);
      z-index: 10;
      overflow: hidden;
    }

    .userAvatar figure{
      display: flex;
      align-items: center;
      align-content: center;
      margin: 0 auto;
      background: #ffffff;
      width: 100%;
      height: 100%;
      transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
    }

    .userAvatar figure:hover{
      // background: rgba(255, 187, 5, 0.8);
    }

    .userAvatar figure .editButton{
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      opacity: 0;
    }

    .userAvatar figure:hover .editButton{
      opacity: 1;
    }

    .userAvatar figure .editButton button{
      width: 100px;
      height: 100px;
      // background: rgba(255, 187, 5, 1) !important;
      background: rgba(70, 70, 70, 0.75) !important;
    }

    .userAvatar figure .editButton button span{
      color: rgba(255, 255, 255, 1) !important;
      font-size: 1.25em;
    }

    .userAvatar .dragDropDisclaimerContenainer{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .userAvatar .dragDropDisclaimerContenainer img{
      max-height: 220px;
    }

    .userAvatar figure .initialsName{
      position: absolute;
      width: 100%;
      height: 100px;
      font-family: $gilroy-extra-bold;
      opacity: 1;
      font-size: 1.5em;
      line-height: 100px;
      top: 0;
      color: rgba(255, 255, 255, 1);
      background: #FF7815 linear-gradient(-45deg, #FF7815, #FFBB05, #FF7815, #FFBB05);
      background-size: 600% 600%;
      -webkit-animation: header-Gradient 25s ease infinite;
      -moz-animation: header-Gradient 25s ease infinite;
      animation: header-Gradient 25s ease infinite;
    }

    .userAvatar figure .initialsName p{
      margin-bottom: 0;
      text-align: center;
    }

    .userAvatar figure:hover .initialsName p{
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
    }

    .userName h1{
      font-size: 1.25em;
      text-align: center;
    }

    .userName p{
      font-size: 1em;
      text-align: center;
      margin-bottom: 0;
    }

    .userName p span.balanceTxt{
      font-size: 0.9em;
    }

    .userName p span.balanceMoney{
      font-family: $gilroy-extra-bold;
      font-size: 0.9em;
    }

    .UserChangeDetail h2{
      margin: 0;
      font-size: 1.25em;
      text-align: center;
    }

    .UserChangeDetail h2 span{
      font-size: 0.75em;
      margin-bottom: 0;
      text-align: center;
      font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
      font-weight: 100;
    }

    .UserChangeDetail p{
      text-align: center;
    }

    .rankingGraphContent{
      width: 100%;
      height: 6px;
      margin-bottom: 2em;
    }

    .rankingGraphContent .rankingGraphNormal{
      float: left;
      width: 25%;
      height: 100%;
      background: #5C7080;
      border-radius: 4px 0 0 4px;
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
    }

    .rankingGraphContent .rankingGraphRegular{
      float: left;
      width: 25%;
      height: 100%;
      background: #DB3737;
    }

    .rankingGraphContent .rankingGraphGood{
      float: left;
      width: 25%;
      height: 100%;
      background: #F29D49;
    }

    .rankingGraphContent .rankingGraphExcelent{
      float: left;
      width: 25%;
      height: 100%;
      background: #3DCC91;
      border-radius: 0 4px 4px 0;
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
    }

    .rankingGraphContent .opacity {
      opacity: 0.333;
    }

    .rankingGraphContent div p{
      font-size: 0.75em;
      text-align: center;
      margin-top: 1em;
    }

  }



  
}
