.wrapperImageZoom {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .imageZoom {
    position: absolute;
    z-index: 20;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transition: unset;
      width: auto;
      height: auto;
      pointer-events: none;
      transform: scale(3);
    }
  }
}
