@import "src/variables";

.individualPanel,
.businessPanel {
  .popOverContent {
    padding: 1em !important;
  }

  .popOverContent h4 {
    color: rgba(70, 70, 70, 1);
    margin-top: 0;
  }

  .popOverContent ul {
    list-style: none !important;
    padding: 0;
  }

  .popOverContent ul li {
    list-style: none !important;
  }

  .iconGreen {
    color: #0d8050;
    margin-right: 0.25em;
  }

  .iconRed {
    color: #c23030;
    margin-right: 0.25em;
  }

  // Avatar
  .initialsAvatarContainer {
    width: 100%;
    height: 100%;
    background: rgba(70, 70, 70, 0.125);
    margin-bottom: 1em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    position: absolute;
    top: 0;
  }

  .initialsContainer {
    text-align: center;
    line-height: 330px;
    font-family: $gilroy-extra-bold;
    color: rgba(70, 70, 70, 1);
    font-size: 1.75em;
  }

  .avatarContainer {
    width: 175px;
    height: 175px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    // position: absolute;
    top: 0;
    left: 0;
  }

  .bp3-tab-panel {
    margin-top: 0;
  }

  .dragDropDisclaimer {
    padding: 1em;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    color: rgba($color: #464646, $alpha: 1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .dragDropDisclaimerContenainer {
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
