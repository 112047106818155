@import "src/variables";

.loginSection {
  input {
    font-family: $gilroy-extra-bold;
  }

  .gradientBackground {
    width: 100%;
    height: 40vh;
    position: absolute;
    // top: 0;
    left: 0;
    right: 0;
  }

  .loginContainer {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 3rem;
  }

  .btnFacebook {
    background: rgb(59, 89, 152) !important;
    border: 0;
    box-shadow: none;
    color: #fff !important;
  }

  .buttonLoginGoogle {
    width: 100%;
  }

  .example-enter {
    opacity: 0.01;
  }

  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .example-leave {
    opacity: 1;
  }

  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}
