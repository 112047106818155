@import 'src/variables';

.HeaderNotificationsAlerts {
  max-width: 22rem !important;
  scroll-behavior: smooth;
  text-align: center;
  overflow-x: hidden;
  max-height: 75vh;

  .cardNotificationAlert {
    min-width: 100% !important;
  }
  .noti-shadow{
    box-shadow: 0px 0px 10px #3344;
    z-index: 2;
    margin-top: 0.5rem;
  }
  .notification{
    border:none;
    padding: 0.25rem 0.5rem;
    background-color: #0000001A;
    .headerUserCard{
      margin-bottom: 0;
      border-radius: 10px;
    }
  }

  .bp3-callout{
    background-color: #fff;
  }

  .bp3-heading {
    font-size: 1.5rem;
    background-color: #fff;
    margin-bottom: 0.5rem;
    z-index: 1;
  }
  .newMessageIcon {
    width: 8px;
    height: 8px;
    background: rgba(0, 123, 255, 1);
    box-shadow: 0 0 0 2px #fff;
    -webkit-box-shadow: 0 0 0 2px #fff;
    -moz-box-shadow: 0 0 0 2px #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    position: absolute;
    z-index: 100;
    top: 16px;
    left: -2px;
  }

  figure.interactiveFigure {
    padding: 0.333em !important;
    margin: 0;
  }

  figure:hover.interactiveFigure {
    background-color: rgba(138, 155, 168, 0.15);
  }

  figure.interactiveFigure .controlButtons {
    opacity: 0.5;
    position: absolute;
    right: 0em;
    z-index: 100;
  }

  figure:hover.interactiveFigure .controlButtons {
    opacity: 1;
  }
}

.headerNavBar .headerUserCard div.AvatarContainerPopover {
  height: 48px;
  padding-bottom: 6%;
  min-width: 48px;
  margin-top: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 0;
  background: #ebebeb;
  position: relative;
  border-radius: 50%;
  justify-content: unset;
}

.headerUserCard .AvatarContainerPopover .AvatarInitials {
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: $gilroy-extra-bold;
  display: flex;
  align-items: center;
  align-content: center;
  color: #464646 !important;
}
