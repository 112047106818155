.AboutLoCambio {
  .video-react .video-react-big-play-button {
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    padding: 0;
    border-radius: 80px;
    -webkit-border-radius: 80px;
    -moz-border-radius: 80px;
    border: 0;
    background-color: rgba(80, 80, 80, 0.9);
  }

  .video-react .video-react-poster {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .video-react .video-react-video {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .video-react .video-react-big-play-button:before {
    color: #ffffff;
    font-size: 2em;
    padding-top: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 2em;
  }
}
