.Profile{

  .User-Avatar-Name figure{
    padding-bottom: 0;
  }

  .initialsName{
    line-height: 170px;
  }

}