.CurrentBalance{

  margin-bottom: 1em;

  .CurrentBalanceContent h1{
    font-size: 1.5em;
    text-align: center;
  }

  .CurrentBalanceContent p{
    text-align: center;
  }

}