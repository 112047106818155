@import 'src/variables';

.HeaderNotificationsMessages {
  width: 22rem !important;
  scroll-behavior: smooth;
  text-align: center;
  overflow-x: hidden;

  .bp3-callout {
    background-color: #fff;
  }

  .bp3-heading {
    font-size: 1.5rem;
  }

  .messager-name {
    text-align: left;
    margin-right: .5em;
  }

  .list-button-wrapper {
    display: flex;
    justify-content: space-evenly;
  }

  .list-button {
    width: 50%;
    padding: .5rem;
    cursor: pointer;
    border-radius: $base-radius;

    &--active, &:hover {
      border: none;

      h3 {
        color: $accent;
      }
    }

    &--active:hover {
      border: none;
    }

    &:hover {
      box-shadow: 0 0 0 1px $accent inset;
    }
  }

  .name-row {
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
  }

  .newMessageIcon {
    width: 8px;
    height: 8px;
    background: rgba(0, 123, 255, 1);
    box-shadow: 0 0 0 2px #fff;
    -webkit-box-shadow: 0 0 0 2px #fff;
    -moz-box-shadow: 0 0 0 2px #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    position: absolute;
    z-index: 100;
    top: calc(50% - 4px);
    left: -2px;
  }

  figure:hover.interactiveFigure {
    background-color: rgba(138, 155, 168, 0.15);
  }

  figure.interactiveFigure .controlButtons {
    opacity: 0.5;
    position: absolute;
    right: 0em;
    z-index: 100;
  }

  figure:hover.interactiveFigure .controlButtons {
    opacity: 1;
  }

  .UserDetailsContainer {
    display: grid;
    justify-content: unset;
  }

  .UserDetailsContainer p {
    color: rgba(70, 70, 70, 1);
  }

  .UserDetailsContainer h4 a {
    color: rgba(70, 70, 70, 1) !important;
  }

  .dateTime {
    color: rgba(70, 70, 70, 0.75);
    font-size: 0.9em;
    font-family: sans-serif;
  }

  .headerUserCard {
    margin-bottom: 1.5rem;
  }

  .dateTime {
    font-family: $gilroy-bold;
    font-size: 0.7rem;
    color: #464646;
    //padding: 10px 10px;
  }
}

.button-chats {
  border: none;
  font-weight: bold;
  :focus {
    outline: none;
  }

  a:focus {
    outline: none;
  }
}

.message-text {
  text-align: left;
  font-size: 0.65rem !important;
}

.article-text {
  font-family: 'gilroy-extra-bold', Helvetica, Arial, serif;
  margin-top: 0.25rem;
}

.purple-text {
  color: #8051da !important;
}

.orange-text {
  color: #ff6d00 !important;
}
