@import 'src/variables';

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $bg-light;
  background-position: center;
  background-size: cover;
  width: 75px;
  height: 75px;
  border-radius: $base-radius;

  @media ($sm) {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }
}