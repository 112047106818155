@import "src/variables";

ul {
  list-style: none;
  padding: 0;
}

ul li p:last-child {
  margin-bottom: 0;
}

.errorInput {
  border: solid 1px #c23030;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

select.errorInput {
  border: solid 1px #c23030;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.checkInput {
  border: solid 1px #0f9960;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

select.checkInput {
  border: solid 1px #0f9960;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

// PASO 1
.cardSelector h1 {
  margin-top: 0;
  color: rgba($color: #464646, $alpha: 0.75) !important;
}

.cardSelector h1 span {
  font-size: 1.4em;
  color: rgba($color: #464646, $alpha: 1) !important;
}

// PASO 3
.bp3-form-helper-text {
  color: #5c7080;
  font-size: 12px;
}

.textAreaWhiteStyle {
  min-height: 250px;
}

.imagesBoxes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  position: relative;
  width: 200px;
  height: 200px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.imageDragZone {
  cursor: pointer;
  height: 0;
  padding-bottom: calc(25% - 4em);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  position: relative;
  box-shadow: none;
}

.imageDragZone:first-child {
  margin: 0;
}

.imageDragZone:nth-child(-n + 4) {
  margin: 0 0.5em 0 0;
}

.imageDragZone:last-child {
  margin: 0;
  background: rgba(138, 155, 168, 0);
}

.croppedImageWrapper {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }
}

.dragDropDisclaimerContenainer {
  width: 100%;
  height: 100%;
}

.dragDropDisclaimerContenainer div {
  position: absolute;
  top: 0;
}

.dragDropDisclaimerContenainer div canvas {
  position: relative;
  z-index: 999 !important;
}

.dragDropDisclaimer {
  padding: 1em;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: rgba($color: #464646, $alpha: 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-width: 0px;
}

.dragDropDisclaimerText {
  padding: 1em;
  padding-top: 25%;
  border-width: 0px;
}

.dragDropDisclaimerText h5 {
  text-align: center;
  margin: 0;
  border-width: 0px;
}

.dragDropDisclaimerText h5 span {
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16",
  sans-serif;
  color: rgba($color: #464646, $alpha: 0.5);
}

.dragDropDisclaimerText p {
  text-align: center;
  font-size: 0.8em;
}

.dragDropDeleteButton {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  z-index: 20;
}

.dragDropDeleteButton button {
  color: rgba(0, 123, 255, 1) !important;
}

.rdw-editor-toolbar {
  border: 0 !important;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
}

.public-DraftStyleDefault-ltr {
  background: #fff;
  padding: 0em;
  font-size: 1.25em;
  margin-bottom: 0.5em !important;
  border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
}

.marginFixOptimazerPublication {
  margin: 0 1em 1em 1em;
}

@media screen and (max-width: 600px) {
  .imagesBoxes {
    display: block;
  }

  .imageDragZone {
    width: 100% !important;
    height: auto;
    padding-bottom: 1em;
  }

  .imageDragZone:last-child {
    max-width: 200px;
    height: 200px;
  }
}

// PASO 4
#resume-image-0,
#resume-image-1,
#resume-image-2,
#resume-image-4,
canvas {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.autocomplete-dropdown-container {
  border-top: 1px solid #e6e6e6;
  box-shadow: 0;
  border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
}

.mapPointChange {
  position: relative;
  background: rgba($color: #464646, $alpha: 0.125);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-bottom: 1em;
}

// PASO 5
.fifthStep {
  .bp3-callout .bp3-heading {
    font-size: 1em;
    color: rgba($color: #464646, $alpha: 0.75) !important;
  }

  .bp3-callout p {
    margin-bottom: 0;
    font-family: $gilroy-extra-bold;
    font-size: 1.25em;
    color: rgba($color: #464646, $alpha: 1);
  }
}

.mapResume {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background: rgba(138, 155, 168, 0.15);
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.ReactCrop__image {
  max-height: fit-content !important;
}

.bp3-dialog {
  padding: 1em;
}



input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type="number"] {
-moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
-moz-appearance: number-input;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}