.PubsAsociatedUser{

  .selectedPub {
    background-color: rgba(138, 155, 168, 0.15);
  }

  .productsItemList{
    
  }

  .productAvatarContainer{

  }
  
  .AvatarImage img{

  }

}

