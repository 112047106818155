section.Registration {
  .gradientBackground {
    width: 100%;
    height: 40vh;
    position: absolute;
    // top: 0;
    left: 0;
    right: 0;
  }

  .registarionContainer {
    max-width: 768px;
    margin: 0 auto;
  }

  .avatarContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  /******************** MEDIA QUERY ********************/

  @media screen and (max-width: 600px) {
    .bp3-tab {
      width: 100% !important;
    }

    .bp3-tab-list {
      display: block;
    }
  }
}
