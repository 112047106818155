@import 'src/variables', 'src/pages/chat/variables';

.panel-buttons {
  display: flex;
  align-items: end;
  justify-content: center;
  grid-column: 1 / -1;
  margin-bottom: 1em;
  margin-top: 1rem;

  &__waiting {
    font-size: 1em;
    font-family: $gilroy-medium !important;
    color: inherit;
    padding: 0.5em 1em;
    background-color: $bg;
    border-radius: $base-radius;
  }

  @media ($sm) {
    justify-content: start;
    grid-column: 2 / -1;
    grid-row: 2 / -1;
    padding: 0 $content-padding;
  }
}