@import "src/variables";

.HeadarProfile {
  .Gilroy600 {
    font-family: $gilroy-light;
    font-weight: 600;
  }

  .Gilroy800 {
    font-family: $gilroy-extra-bold;
    font-weight: 800;
  }

  .CoverImageProfile {
    width: 100%;
    background: #c7c7c7 !important;
    margin: 0 auto;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    position: relative;
    z-index: 0;
  }

  .coverWithOutImage {
    width: 100%;
    margin-left: 5%;
    height: auto;
    min-height: 50px;
    background: #c7c7c7 !important;
    margin: 0 auto;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    position: relative;
    z-index: 10;
    height: 50px;
    margin-top: 100px;
  }

  .backgroundImageAvatar {
    background: rgba(70, 70, 70, 0.125);
    width: 100%;
    height: auto;
  }

  .backgroundWithoutImage {
    background: rgba(70, 70, 70, 0.125);
    width: 100%;
    height: 0px;
  }

  .backgroundImageAvatar figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px px 0 0;
    -moz-border-radius: 3px 3px 0 0;
  }

  .backgroundImageAvatar figure .backgroundImage {
    z-index: 1;
    /* transform: scale3d(1.5, 1.5, 1.5);
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    -moz-transform: scale3d(1.5, 1.5, 1.5); */
    width: 100%;
    background: #ececec;
  }

  .backgroundImageAvatar figure .backgroundImage img {
    // height: 100%;
    // object-fit: cover;
    // object-position: top;
    // filter: blur(0px);
    // -webkit-filter: blur(0px);
    // -moz-filter: blur(0px);
  }

  .backgroundImageAvatar figure:hover .backgroundImage img {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
  }

  .backgroundImageAvatar figure .overlayBackgroundImageAvatar {
    width: 100%;
    height: 100%;
    background: rgba(70, 70, 70, 1);
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-content: center;
    align-items: center;
    opacity: 0;
    padding: 1em;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
  }

  .backgroundImageAvatar figure .overlayBackgroundImageAvatarWithoutImage {
    width: 100%;
    height: 30%;
    background: rgba(70, 70, 70, 1);
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-content: center;
    align-items: center;
    opacity: 0;
    padding: 1em;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
  }

  .backgroundImageAvatar figure:hover .overlayBackgroundImageAvatar {
    opacity: 1;
    background: rgba(70, 70, 70, 0.75);
  }

  .backgroundImageAvatar figure .overlayBackgroundImageAvatar p {
    color: rgba(255, 255, 255, 0);
    text-align: center;
    font-size: 1em;
  }

  .backgroundImageAvatar figure:hover .overlayBackgroundImageAvatar p {
    color: rgba(255, 255, 255, 1);
  }

  .backgroundImageAvatar figure .editButton {
    position: absolute;
    z-index: 3;
    top: 0.5em;
    left: 0.5em;
    transform: translateX(-50px);
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
  }

  .backgroundImageAvatar figure:hover .editButton {
    position: absolute;
    z-index: 3;
    top: 0.5em;
    left: 0.5em;
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
  }

  .gradientCoverImageProfile {
    // width: 100%;
    // height: 33%;
    // position: absolute;
    // bottom: 0;
    // background: -moz-linear-gradient(
    //   top,
    //   rgba(253, 251, 248, 0) 0%,
    //   rgba(253, 251, 248, 1) 95%
    // ); /* FF3.6-15 */
    // background: -webkit-linear-gradient(
    //   top,
    //   rgba(253, 251, 248, 0) 0%,
    //   rgba(253, 251, 248, 1) 95%
    // ); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(
    //   to bottom,
    //   rgba(253, 251, 248, 0) 0%,
    //   rgba(253, 251, 248, 1) 95%
    // ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fdfbf8', endColorstr='#fdfbf8',GradientType=0 ); /* IE6-9 */
  }

  .User-Avatar-Name {
    width: 100%;
    height: 100%;
    // padding-bottom: 100%;
  }

  .User-Avatar-Name figure {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .avatarContainer {
    width: 100%;
    height: 100%;
  }

  .User-Avatar-Name {
    margin-right: 0.5em;
    width: 100%;
    height: 100%;
    background: rgba(138, 155, 168, 0.15);
    color: #464646;
    align-items: center;
    align-content: center;
    z-index: 10;
    overflow: hidden;
  }

  .User-Avatar-Name figure {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    background: #ffffff;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
  }

  .User-Avatar-Name figure:hover {
    background: #ffffff;
  }

  .User-Avatar-Name figure .editButton {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    opacity: 0;
  }

  .User-Avatar-Name figure:hover .editButton {
    opacity: 1;
  }

  .User-Avatar-Name figure .editButton button {
    margin-right: 0.5em;
    background: rgba(70, 70, 70, 0.75) !important;
  }

  .User-Avatar-Name figure .editButton button span {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 1.25em;
  }

  .User-Avatar-Name .dragDropDisclaimerContenainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .User-Avatar-Name .dragDropDisclaimerContenainer img {
    max-height: 220px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .User-Avatar-Name figure .initialsName {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: $gilroy-extra-bold;
    color: rgba(70, 70, 70, 1);
    opacity: 1;
    font-size: 1.5em;
    background: #ebebeb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    line-height: 200px;
  }

  .User-Avatar-Name figure:hover .initialsName p {
    filter: blur(10px);
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
  }

  .User-Avatar-Name figure:hover .noBlur p {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
  }

  .userName h1 {
    font-size: 1em;
    text-align: center;
  }

  .userName p {
    font-size: 1em;
    text-align: center;
    margin-bottom: 0;
  }

  .AvatarProfileContainer {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    overflow: hidden;
    padding: 0.25em;
    margin: 0 auto;
    position: relative;
  }

  div.AvatarContainer {
    position: relative;
    width: 100%;
    height: 0;
    background: #ebebeb;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    overflow: hidden;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
  }

  .AvatarContainer .AvatarInitials {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: $gilroy-extra-bold;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .AvatarContainer .AvatarInitials p {
    text-align: center;
    margin-bottom: 0;
  }

  .AvatarContainer .AvatarImage {
    position: absolute;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .AvatarContainer .AvatarImage img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .AvatarProfileDragZone {
    background: #ebebeb;
  }

  .AvatarProfileName h1 {
    font-size: 1.5em;
  }

  .OverlapContent {
    width: 100%;
    margin-top: 1em;
    position: relative;
    // margin-left: 5%;
    // width: 90%;
  }

  .UserChangeDetail h2 {
    text-align: center;
  }

  .UserChangeDetail p {
    text-align: center;
  }

  .AvatarInitials {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  .AvatarInitials p {
    font-family: $gilroy-extra-bold;
    font-size: 1.5em;
    line-height: 220px;
    text-align: center;
  }

  .ReactCrop {
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }

  .UserChangeDetail .PubCount h2 {
    font-size: 1.5em;
  }

  .RankBlock {
    text-align: center;
    margin-top: -15px;
  }

  .newUserText {
    text-align: center;
    margin-top: -15px;
  }
}
