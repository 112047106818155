@import 'src/variables', 'src/pages/chat/variables';

$unread-size: 1.25em;

.item {
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr $gap $unread-size;
  grid-template-rows: min(75px);
  margin: $gap;
  padding: $gap;
  border-radius: $base-radius;
  background-color: rgba(255, 255, 255, 0.5);

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__active {
    background-color: rgb(255, 255, 255);
  }

  &__content {
    display: grid;
    align-content: space-evenly;
    padding-left: $gap;
  }

  &__name {
    font-size: 1em;
  }

  &__title {
    font-size: 1.25em;
    line-height: normal;
    color: $accent;
  }

  &__text {
    font-size: .75em;
    font-family: $gilroy-medium;
  }

  &__unread {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $unread-size;
    height: $unread-size;
    background-color: $blue;
    grid-column: 4;
    align-self: center;
    border-radius: 50%;
  }

  &__unread-count {
    color: white;
    font-size: .75em;
    font-family: $gilroy-extra-bold;
  }

  &.bp3-elevation-0:not(.bp3-card.bp3-interactive:hover) {
    box-shadow: $shadow;
  }

  @media ($lg) {
    margin: $gap calc(#{$gap} / 2) $gap $gap;
  }
}