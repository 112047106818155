@import "src/variables";

.ChangeProposalsSent {
  min-width: 100% !important;

  h1 {
    font-size: 1em;
  }

  h1 a {
    font-size: inherit;
    color: rgba(0, 123, 255, 1) !important;
  }
}

.NoProposal {
  font-family: $gilroy-light;
  font-weight: 800;
}

.Box {
  margin-left: 2rem;
}

.productSwiperCompact {
  max-height: 50px;
  margin-left: 1em;
  margin-bottom: 0.4em;
}

.showProposalLink {
  display: flex;
  width: 100%;
}
