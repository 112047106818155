@import 'src/variables', 'src/pages/chat/variables';

.message {
  display: grid;
  grid-template-rows: 1fr auto;
  font-family: $gilroy-medium;
  margin-bottom: .5em;
  position: relative;

  &--sent {
    justify-items: end;

    .message__text {
      color: $font-light;
      background-color: $bg;
      border-radius: 1em 1em 0 1em;
    }
  }

  &--received {
    justify-items: start;

    .message__text {
      color: white;
      background-color: $accent;
    }
  }

  &__date {
    font-size: 1em;
    margin-top: .375em;
    width: fit-content;
  }

  &__text {
    font-size: 1.125em;
    line-height: 1;
    padding: .875em 1.25em;
    border-radius: 1em 1em 1em 0;
    max-width: 70%;
    word-break: break-word;
  }

  &__image {
    border-radius: $base-radius;
    width: $attachment-image-size;
    height: $attachment-image-size;
    background-color: $bg;
    object-fit: contain;
    cursor: zoom-in;
  }

  &__map {
    border-radius: $base-radius;
    width: $attachment-image-size;
    height: $attachment-image-size;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: zoom-in;
    }
  }

  &__loader {
    position: absolute;
    top: .5em;
    right: .5em;
    width: .5em;
    height: .5em;
  }

  .AvatarInitials, .AvatarImage {
    width: 100%;
    height: $message-image-size;
  }
}