.HalfPage{
  width: 300px;
  margin-bottom: 1em;

  .adImage {
    width: 300px;
  }

  h5{
    color: rgba(0, 123, 255, 1) !important;
  }

}