.ChangeOperationContainer {

  .SwiperContainer {
    width: 100%;
    margin-bottom: 40px;
    padding: 10px;
    background: #ebebeb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .mapChangePoint {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: #ebebeb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    position: relative;
    margin-bottom: 2em;
  }

  .mapDetailProduct {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }

  .publicationName h1 {
    font-size: 1.25em;
    color: rgba(0, 123, 255, 1) !important;
  }

  .SelectionPublicationsList {
    width: 100%;
    height: 300px;
    min-height: 300px;
    overflow-y: auto;
    padding: 0.5em;
  }

  .avatarWrapper {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 5px;
    }
  }

}
