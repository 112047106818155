@import 'src/variables';

.home {
  font-size: 0.9rem;
  font-family: $gilroy;
  padding-top: $header-gap;

  .section {
    margin-bottom: 2.75em;

    &--with-padding {
      margin-bottom: 0;
      padding-bottom: 3em;
    }

    &--with-max-width {
      max-width: calc(1050px + 1em);
      margin-right: auto;
      margin-left: auto;
      padding: 0 1em;
    }

    &__title {
      font-size: 2em;
      text-align: center;
      margin-bottom: 1em;
      line-height: 1em;

      &--bolder {
        font-family: $gilroy-extra-bold;
      }

      &--accent {
        color: $accent;
      }

      &--primary {
        color: $primary;
      }
    }
  }

  .servicesLC {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      gap: 1em;
    }

    &__service {
      position: relative;
      min-width: 9rem;
      flex: 1 0 calc(25% - 1em);
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0 3px 6px #00000029;
    }

    &__status-Bar {
      width: 100%;
      left: 32%;
      top: 5%;
      transform: rotate(45deg);
      position: absolute;
      padding: 0.5rem 0;
      background-color: $accent;
      color: white;
      text-align: center;
      font-size: 10px;
      font-family: $gilroy-extra-bold;
      text-transform: uppercase;
      z-index: 1;

      &--grey {
        background-color: #464646;
      }
    }

    &__logo {
      width: 100%;

      &--grey {
        filter: grayscale(1);
      }
    }

    &__info {
      height: 160px;
    }

    &__title {
      font-size: 1.25em;
      padding: 1em;
      margin: auto 0;
      color: $primary;
      font-family: $gilroy-extra-bold;
      text-transform: uppercase;

      &--accent {
        color: $accent;
      }
    }

    &__description {
      padding: 0 1em;
      text-align: left;
    }

    &__button-Container {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
    }

    &__button {
      background-color: $accent;
      color: white;
      padding: 1rem 3rem;
      width: fit-content;
      border-radius: 5px;
      cursor: pointer;

      &--grey {
        background-color: #464646;
        cursor: not-allowed;
      }
    }
  }

  .categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__container {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      gap: 1em;
    }

    &__category {
      min-width: 9rem;
      flex: 1 0 calc(25% - 1em);
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0 3px 6px #00000029;
    }

    &__image {
      width: 100%;
    }

    &__name {
      font-size: 1.25em;
      padding: 1em 0;
      margin: auto 0;
    }

    .swiper-initialized {
      .swiper-button-next,
      .swiper-button-prev {
        &:not(.swiper-button-disabled) {
          color: white;
          background-color: $accent;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
        background: rgb(232, 232, 232);
        background: linear-gradient(
          90deg,
          $bg 0%,
          transparent 20%,
          transparent 80%,
          $bg 100%
        );
      }
    }
  }

  .faq {
    position: relative;
    display: flex;
    justify-content: flex-end;
    background: linear-gradient(
      180deg,
      rgba(248, 183, 35, 1) 37%,
      rgba(255, 109, 0, 1) 100%
    );

    &__cover {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      max-height: 34em;
      background-image: url('../../images/faq-min.png');
      background-repeat: no-repeat;
      background-position: -4em 100%;
      background-size: contain;
      pointer-events: none;
      z-index: 1;
    }

    &__container {
      width: 100%;
      z-index: 2;
      margin: 0 2em;
    }

    &__title {
      font-family: $gilroy-extra-bold;
      font-size: 5em;
      color: white;
      text-align: center;
    }

    &__button {
      position: relative;
      font-family: $gilroy-extra-bold;
      font-size: 1em;
      text-align: center;
      color: $accent;
      padding: 1.5em 4.5em;
      line-height: 1.5;
      border-radius: 5px;

      &:hover {
        background-color: #DDD;
      }
    }

    &__icon {
      position: absolute;
      top: calc(50% - 0.5em);
      right: 2em;
      color: $accent;

      svg {
        width: 1.5em;
        height: 1.5em;
      }

      &--active {
        transform: rotate(180deg);
      }
    }

    &__content {
      text-align: center;
      box-shadow: none;
      margin-top: 0.5rem;
      border-radius: 5px;
      background-color: $accent;
      color: white;

      p {
        b, i, a {
          margin: 0.25em;
        }
        a {
          color: inherit !important;
          font-weight: bold;
        }
      }
    }
  }

  .slider {
    .swiper-button-next,
    .swiper-button-prev {
      top: calc(50% - 1em);
    }

    .swiper-initialized {
      padding-bottom: 20px;
    }

    .swiper-pagination-bullets {
      bottom: 0 !important;
    }

    .swiper-pagination-bullet-active {
      background: $accent;
    }
  }

  .video {
    background: linear-gradient(180deg, $bg, rgba(248, 183, 35, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__title {
      font-size: 3.25em;
      margin-top: 0.5em;
      width: 100%;
    }

    &__iframe {
      width: 100%;
      height: 40vw;
    }
  }

  .swiper-initialized {
    .swiper-button-next,
    .swiper-button-prev {
      color: $accent;
      width: 2.75em;
      height: 2.75em;
      background-color: #FFFFFFCC;
      border-radius: 50%;
      cursor: pointer;

      &::after {
        font-size: 1.25em !important;
        font-weight: bold;
      }
    }

    .swiper-button-prev {
      padding-right: 0.25em;
    }

    .swiper-button-next {
      padding-left: 0.25em;
    }
  }

  .footer {
    &__directory {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3em 0 0 0;
      font-size: 1rem;
    }

    &__section {
      text-align: center;
      padding-bottom: 2em;
    }

    &__section-title {
      font-size: 1.25rem;
      margin-bottom: 0.5em;
    }

    &__section-destiny {
      color: $accent;
      font-family: $gilroy-medium;
      padding: 0.5rem 0;
    }

    &__grey-bar {
      font-size: 1rem;
      padding: 2em 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #464646;
    }

    &__disclaimer {
      font-family: $gilroy-bold;
      text-align: center;
      color: white;
      padding: 2em 3em;
      margin: 0;
    }

    &__logo {
      width: 200px;
      margin-top: -10px;
    }
  }
}

@media ($md) {
  .home {
    font-size: 1rem;

    .servicesLC {
      &__status-Bar {
        left: 35%;
      }

      &__info {
        height: 190px;
      }
    }

    .faq {
      &__container {
        width: 60%;
      }
    }

    .categories__name {
      font-size: 0.9em;
    }

    .footer {
      &__directory {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
        padding: 3em 0;
      }

      &__section {
        text-align: left;
        padding: initial;
      }

      &__grey-bar {
        flex-direction: row;
      }

      &__disclaimer {
        padding: 0 3em;
      }
    }
  }
}

@media ($lg) {
  .home {
    font-size: 1.125rem;

    .servicesLC {
      &__status-Bar {
        font-size: 12px;
      }

      &__info {
        height: 200px;
      }
    }

    .faq {
      &__container {
        width: auto;
      }
    }
  }
}

@media ($xl) {
  .home {
    font-size: 1.25rem;

    .servicesLC {
      &__status-Bar {
        font-size: 14px;
      }

      &__info {
        height: 230px;
      }
    }

    .faq {
      &__container {
        width: 80%;
      }
    }

    .footer {
      &__directory {
        margin: 0 10em;
      }
    }
  }
}