.SuccessfulChanges{

  h1 {
    font-size: 1em;
  }

  h1 a{
    font-size: inherit;
    color: rgba(0, 123, 255, 1) !important;
  }

}