.CreditCardList{

  .headerUserCard{
    margin-bottom: 0;
  }

  .logoBrandCC{
    width: 80px;
  }

  .logoBrandCC img{
    width: 80px;
  }

}