@import "src/variables";

.headerNotificationsSaved {
  max-width: 22rem !important;
  scroll-behavior: smooth;
  text-align: center;
  overflow-x: hidden;

  .bp3-callout{
    background-color: #fff;
  }

  .bp3-heading {
    font-size: 1.5rem;
    background-color: #fff;
    margin-bottom: 0.5rem;
    z-index: 1;
  }
  .newMessageIcon {
    width: 8px;
    height: 8px;
    background: rgba(0, 123, 255, 1);
    box-shadow: 0 0 0 2px #fff;
    -webkit-box-shadow: 0 0 0 2px #fff;
    -moz-box-shadow: 0 0 0 2px #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    position: absolute;
    z-index: 100;
    top: calc(50% - 4px);
    left: -2px;
  }

  figure.interactiveFigure {
    padding: 0.25em !important;
  }

  figure:hover.interactiveFigure {
    background-color: rgba(138, 155, 168, 0.15);
  }

  figure.interactiveFigure .controlButtons {
    opacity: 0.5;
    position: absolute;
    right: 0em;
    z-index: 100;
  }

  figure:hover.interactiveFigure .controlButtons {
    opacity: 1;
  }

  .headerNavBar .headerUserCard div.AvatarContainerPopover {
    height: 48px;
    padding-bottom: 6%;
    min-width: 48px;
    margin-top: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0;
    background: #ebebeb;
    position: relative;
    border-radius: 50%;
  }
  
  .headerUserCard .AvatarContainerPopover .AvatarInitials {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: $gilroy-extra-bold;
    display: flex;
    align-items: center;
    align-content: center;
    color: #464646 !important;
  }
}

.bp3-alert {
  padding: 0;

  .bp3-alert-footer {
    padding: 1em;
  }

  .bp3-alert-footer button:first-child {
    font-family: $gilroy-extra-bold;
    background: rgba(0, 123, 255, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
      inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
      inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    -moz-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
      inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  }

  .bp3-alert-footer button:last-child {
    font-family: $gilroy-extra-bold;
    background: #c23030;
    color: #ffffff !important;
  }
}

