@import 'src/variables';

.button {
  font-family: $gilroy-medium;
  font-size: 1em;
  padding: .5em 1em;
  box-shadow: 0 0 10px #00000029 !important;
  border-radius: .375rem;
  background-image: none !important;

  &--success:not(.bp3-disabled) {
    background-color: #54D454 !important;

    &:hover {
      background-color: darken(#54D454, 10%) !important;
    }

    &:active {
      background-color: darken(#54D454, 20%) !important;
    }
  }

  &--danger:not(.bp3-disabled) {
    background-color: #D64747 !important;

    &:hover {
      background-color: darken(#D64747, 10%) !important;
    }

    &:active {
      background-color: darken(#D64747, 20%) !important;
    }
  }

  &--accent:not(.bp3-disabled) {
    background-color: $accent !important;
    color: white;

    &:hover {
      background-color: darken($accent, 10%) !important;
    }

    &:active {
      background-color: darken($accent, 20%) !important;
    }
  }

  &--primary:not(.bp3-disabled) {
    background-color: $primary !important;
    color: white;

    &:hover {
      background-color: darken($primary, 10%) !important;
    }

    &:active {
      background-color: darken($primary, 20%) !important;
    }
  }

  &--bold {
    font-family: $gilroy-extra-bold;
  }

  &--min-with {
    min-width: 75px;
  }

  &.bp3-large {
    font-size: 1.25em;
    padding: .75em 1.5em;

    & .bp3-icon svg {
      width: 1.25em;
      height: 1.25em;
    }
  }
}