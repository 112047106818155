.ChangeOperationStep3 {
  .publicationName h1 {
    font-size: 1.5em;
    color: rgba(0, 123, 255, 1) !important;
    margin-top: 0;
  }

  .QRCodeContainer {
    background: #fff;
    padding: 1em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    display: -webkit-inline-flex;
  }
}
