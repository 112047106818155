// TODO: split every group of variables in files

// Colors
$accent: #8051DA;
$primary: #FF6D00;

$bg: #E8E8E8;
$bg-light: #EFEFEF;

$font: #2D2D2D;
$font-light: #464646;

$blue: #479FD6;

$shadow-color: #00000029;

// Fonts
$defaults: Helvetica, Arial, serif;
$gilroy-heavy: 'gilroy-heavy', $defaults;
$gilroy-extra-bold: 'gilroy-extra-bold', $defaults;
$gilroy-bold: 'gilroy-bold', $defaults;
$gilroy-medium: 'gilroy-medium', $defaults;
$gilroy: 'gilroy', $defaults;
$gilroy-light: 'gilroy-light', $defaults;

// Radius
$base-radius: .5rem;

// Header
$header-gap: 0;

// Breakpoints
// TODO: Use tailwind screens
$sm: 'min-width: 576px';
$md: 'min-width: 769px';
$lg: 'min-width: 1025px';
$xl: 'min-width: 1200px';
