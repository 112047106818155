
// ---------- MEDIA QUERY ---------- //
@media (max-width: 1024px) {
  .activityWall .swiper-slide {
    width: 216px;
    height: auto;
    margin-top: 3rem;
  }

  .FechaPub {
    margin-bottom: 35px;
  }

}
