.SearchListFilter{

  .current {
    background-color: rgba(0, 123, 255, 1) !important;
    color: #fff !important;
  }

  .bp3-button-group.bp3-vertical .bp3-button{
    text-transform: capitalize;
  }

  .input-range{
    display: inline-flex;
  }

  .input-range input{
    width: 50px;
    border: 1px solid rgba(138, 155, 168, 0.15);
    border-radius: 3px;
    margin-left: 8px;

  }

}
