.middle-navbar {
  flex-grow: 1;
  font-family: 'gilroy-light', Helvetica, Arial, serif;
  margin: 1rem 0;
  .search-bar {
    background-color: #fff;
    border-radius: 5px;
    border-top-left-radius: 0;
    align-items: center;

    .full-width {
      width: 100%;
    }

    .header-search-bar {
      background: #fff;
      border-radius: 5px;
      height: auto;
      border: none;
      box-shadow: none;
      width: 95%;
      font-family: 'gilroy-light', Helvetica, Arial, serif;
      font-size: 15px;
    }
    input:focus,
    input.form-control:focus {
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }

    .padding-search-bar {
      padding: 0.85rem 1rem;
      font-size: 16px;
      cursor:pointer;
    }

    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }


    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .inputSearchStyle input {
      background: #fff;
      border: 0;
      border-radius: 0 !important;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      box-shadow: none !important;
      // max-width: 240px;
    }

    .inputSearchStyle input:hover {
      border: 0;
      border-radius: none !important;
      -webkit-border-radius: none !important;
      -moz-border-radius: none !important;
      box-shadow: none !important;
    }

    .bp3-popover-enter-done {
      width: 33% !important;
    }
  }
  .search-tab {
    padding: 1rem 1.5rem;
    color: white;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'gilroy-extra-;;1bold', Helvetica, Arial, serif;
  }

  .search-tab-left {
    border-top-left-radius: 5px;
  }

  .search-tab-right {
    border-top-right-radius: 5px;
  }

  .disabled {
    background-color: #e8e8e8;
    color: #2d2d2d;
  }

  .active-lo-tengo {
    background-color: #ff6d00;
  }

  .active-lo-quiero {
    background-color: #8051da;
  }

  .search-bar-text {
    padding: 0.85rem 1rem;
    color: #2d2d2d;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .active-lo-tengo-text {
    color: #ff6d00;
  }

  .active-lo-quiero-text {
    color: #8051da;
  }
  /******************** MEDIA QUERY ********************/

  @media screen and (max-width: 759px) {
    margin-top: 0;
    margin-bottom: 1rem;

    .Group2 {
      width: 100%;
      float: none;
      margin: 0px auto;
    }

    .searchbarBtn {
      display: none;
    }

    .Group3 {
      display: none;
    }

    .search-tab {
      padding: 0.5rem 0.5rem;
      text-align: center;
      width: 50%;
    }

    .search-bar {
      border-top-right-radius: 0;
    }

    .search-bar-text {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 580px) {
    .Group2 {
      // max-width: 24rem;
      width: 100%;
      margin: 0px auto;
      float: none;
    }
  }

  @media screen and (max-width: 410px) {
    .DepButton {
      display: none;
    }

    .Group2 {
      width: 100%;
      margin: 0px auto;
      float: none;
    }

  }
}
