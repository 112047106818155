.productSwiper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto !important;
      max-height: 210px;
    }
  }

  .swiper-container {
    max-width: 100%;
  }
}
