@import 'src/variables', 'src/pages/chat/variables';

.panel-header {
  background-color: white;
  overflow: hidden;

  .AvatarImage, .AvatarInitials {
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
  }

  &__navigation {
    display: grid;
    grid-template-columns: 3.5em auto 1fr 3.5em;
    grid-template-rows: 3.5em;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--down {
      transform: rotate(180deg);
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: .75em;
  }

  &__image-wrapper {
    grid-row: 1 / 2;
  }

  &__content-wrapper {
    display: grid;
    grid-template-columns: 75px 1fr auto;
    grid-template-rows: repeat(3, auto);
    align-items: center;
    border-bottom: 1px solid $shadow-color;
    padding: 0 .5em;
  }

  &__content {
    display: grid;
    grid-template-rows: repeat(2, auto) 1fr;
    padding: 0 $content-padding;
  }

  &__title {
    display: inline;
    font-family: $gilroy-extra-bold;
    font-size: 1.5em;

    &--accent {
      color: $accent;
    }

    &--primary {
      color: $primary;
    }

    &--sub-title {
      font-family: $gilroy-medium;
      font-size: .875em;
      font-weight: normal;
      color: #464646;
    }
  }

  &__date {
    font-size: .75em;
    font-family: $gilroy-medium;
    grid-column: 1 / -1;
    grid-row: 1;
    text-align: center;
  }

  @media ($sm) {
    box-shadow: $shadow;
    border-radius: $base-radius;

    &__navigation {
      display: none;
    }

    &__content-wrapper {
      grid-template-columns: 145px 1fr auto;
      align-items: unset;
      border-bottom: unset;
      padding: unset;
    }

    &__date {
      grid-column: unset;
      grid-row: unset;
      text-align: left;
    }

    &__content {
      padding: $content-padding $content-padding 0;
    }

    &__image-wrapper {
      grid-row: 1 / -1;
    }
  }
}