@import "src/variables";

.headerUserCard {
  // justify-content: unset;
}

.HeaderNotificationsAlerts {
  background-color: #fff;
}

.headerUserCard div.AvatarContainer {
  // justify-content: space-between;
}

.headerUserCard .UserDetailsContainer {
  // justify-content: unset;
}

.headerUserCard div {
  // justify-content: space-between;
}

.headerUserCard .AvatarContainer .AvatarInitials p {
  // margin-left: 15px;
}

.changeDesignLabel {
  padding: 0.5em;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-flex;
  position: relative;
}

.changeDesignLabel h2 {
  font-size: 1em;
  vertical-align: middle;
  color: #fff !important;
}

.changeDesignLabel span.bp3-icon svg {
  font-size: 2em;
  fill: #fff;
}

.NotBox {
  max-width: 1200px;
  min-height: 14.5rem;
  margin: 0px auto;
  border: 1px solid rgb(169, 169, 169);
}

.Header1 {
  font-family: $gilroy-extra-bold;
  font-weight: 600;
}

.AvatarInitials {
  // justify-content: space-around;
}

.Line2 {
  justify-content: space-between;
  flex-flow: unset;
  display: inline-block;
}

.NotDate {
  font-family: $gilroy-light;
  font-size: 0.7rem;
  font-weight: 600;
  color: #464646;
  // padding: 10px 10px;
}

.NotDate2 {
  font-family: $gilroy-light;
  font-size: 0.7rem;
  font-weight: 600;
  color: #464646;
  //padding: 10px 10px;
}


/********************  MEDIA QUERY ********************/

